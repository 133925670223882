/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components

// Helpers & utils

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Textarea({
  type,
  name,
  isRequired,
  wasSubmitted,
  label,
  pattern,
  fieldErrorMessage,
  clearInput,
  ...props
}) {
  /* ******************************** HOOKS ******************************* */
  const [value, setValue] = useState();
  const [touched, setTouched] = useState(false);

  function getFieldError() {
    if (isRequired) {
      if (value === undefined || !value.length > 0) {
        return fieldErrorMessage?.empty;
      }
      if (pattern !== undefined && fieldErrorMessage !== undefined) {
        if (value.match(pattern) === null) {
          return fieldErrorMessage?.pattern;
        }
      }
    }

    return null;
  }

  const errorMessage = getFieldError();
  const displayErrorMessage = (wasSubmitted || touched) && errorMessage;

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleBlur() {
    setTouched(true);
  }

  useEffect(() => {
    if (clearInput) {
      setValue('');
      setTouched(false);
    }
  }, [clearInput]);

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="custom-textarea">
      <label htmlFor={name}>{label}</label>
      <textarea
        name={name}
        id={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
      {displayErrorMessage ? (
        <span role="alert" id={`${name}-error`} className="error-message">
          {errorMessage}
        </span>
      ) : null}
    </div>
  );
}

Textarea.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  pattern: PropTypes.string,
  isRequired: PropTypes.bool,
  wasSubmitted: PropTypes.bool,
  clearInput: PropTypes.bool,
  fieldErrorMessage: PropTypes.objectOf(PropTypes.string, PropTypes.string),
};

Textarea.defaultProps = {
  type: undefined,
  name: undefined,
  label: undefined,
  pattern: undefined,
  isRequired: false,
  wasSubmitted: false,
  clearInput: false,
  fieldErrorMessage: undefined,
};

export default Textarea;
