/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// assets
import HeroImage from '../../../images/shared-images/main-img.webp';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function HeroContact() {
  /* ******************************** CONSTANTS ******************************* */
  // localization
  const { t } = useTranslation();
  const { title, email, phone, adresse } = t('heroContact', {
    returnObjects: true,
  });
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="contact-hero" fluid>
      <Row>
        <Col sm={12} lg={6} xl={5}>
          <h1 className="contact-hero-title">{title}</h1>
          <p className="contact-hero-description">{email}</p>

          <p className="contact-hero-description">{phone}</p>
          <p className="contact-hero-description">{adresse}</p>
        </Col>
        <Col sm={12} lg={6} xl={7} className="contact-hero-img-container">
          <img src={HeroImage} alt="" />
        </Col>
      </Row>
    </Container>
  );
}

HeroContact.propTypes = {};

HeroContact.defaultProps = {};

export default HeroContact;
