/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Input({
  type,
  name,
  isRequired,
  label,
  pattern,
  fieldErrorMessage,
  placeholder,
  wasSubmitted,
  clearInput,
}) {
  /* ******************************** HOOKS ******************************* */
  const [value, setValue] = useState(type === 'number' ? 0 : '');
  const [touched, setTouched] = useState(false);

  /* ******************************** CALLBACKS ******************************* */
  function getFieldError() {
    if (isRequired) {
      if (value === undefined || !value.length > 0) {
        if (type === 'number' && value < 1) {
          return fieldErrorMessage?.empty;
        }

        return fieldErrorMessage?.empty;
      }
      if (value.match(pattern) === null) {
        return fieldErrorMessage?.pattern;
      }
    }

    return null;
  }

  const errorMessage = getFieldError();
  const displayErrorMessage = (wasSubmitted || touched) && errorMessage;

  function handleChange(e) {
    if (type === 'number' && e.target.value < 0) {
      return;
    }
    setValue(e.target.value);
  }

  function handleBlur() {
    setTouched(true);
  }

  useEffect(() => {
    if (clearInput) {
      setValue(type === 'number' ? 0 : '');
      setTouched(false);
    }
  }, [clearInput, type]);

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="custom-input">
      <label htmlFor={name}>{label}</label>
      <div className="input-container">
        <input
          type={type}
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {/* {type === 'number' ? (
          <>
            <span
              tabIndex={0}
              className="step-up"
              role="button"
              onClick={() => setValue(String(Number(value) + 1))}
              onKeyDown={() => setValue(String(Number(value) + 1))}
            >
              up
            </span>
            <span
              tabIndex={0}
              className="step-down"
              role="button"
              onClick={() => {
                if (value > 0) {
                  setValue(String(Number(value) - 1));
                }
              }}
              onKeyDown={() => {
                if (value > 0) {
                  setValue(String(Number(value) - 1));
                }
              }}
            >
              down
            </span>
          </>
        ) : null} */}
      </div>
      {displayErrorMessage ? (
        <span role="alert" id={`${name}-error`} className="error-message">
          {errorMessage}
        </span>
      ) : null}
    </div>
  );
}

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  wasSubmitted: PropTypes.bool,
  clearInput: PropTypes.bool,
  fieldErrorMessage: PropTypes.objectOf(PropTypes.string, PropTypes.string),
};

Input.defaultProps = {
  type: undefined,
  name: undefined,
  label: undefined,
  pattern: undefined,
  placeholder: undefined,
  isRequired: false,
  wasSubmitted: false,
  clearInput: false,
  fieldErrorMessage: undefined,
};

export default Input;
