import queryString from 'qs';

export const sarlFolderQueries = () => {
  return queryString.stringify({
    populate: {
      businessLicense: {
        populate: {
          SarlNameProposition: { populate: true },
          SarlAddress: { populate: { addressFile: { populate: true } } },
          SarlDuration: {
            populate: true,
          },
          SarlCapitalStructure: {
            populate: {
              identity: { populate: true },
              residence: { populate: true },
              nationality: { populate: true },
            },
          },
          SarlRepresentative: {
            populate: {
              identity: { populate: true },
              nationality: { populate: true },
            },
          },
          nameReservation: { populate: { file: { populate: true } } },
          specifications: { populate: { file: { populate: true } } },
        },
      },
      investmentDeclaration: {
        populate: {
          ProductOrService: { populate: true },
          SarlAuthorization: { populate: true },
          ForecastInvestment: { populate: true },
          SarlFinance: { populate: true },
          SarlFinancePlan: { populate: true },
          SarlProgramList: { populate: true },
          APIIApproval: { populate: { file: { populate: true } } },
        },
      },
      Status: {
        populate: {
          SarlBankAccount: { populate: true },
          iban: { populate: { file: { populate: true } } },
          SarlStatusModel: {
            populate: {
              file: { populate: true },
              filePostModification: { populate: true },
            },
          },
          SarlStatusValidated: { populate: { file: { populate: true } } },
        },
      },
      TaxRegistration: {
        populate: {
          declaration: { populate: true },
          patente: { populate: true },
        },
      },
      CommerceRegistryRegistration: {
        populate: {
          EffectiveBeneficiary: { populate: { file: { populate: true } } },
          EffectiveBeneficiaryDownload: {
            populate: { file: { populate: true } },
          },
          CommerceRegistryDownload: { populate: { file: { populate: true } } },
        },
      },
      PostCreationProcedure: {
        populate: {
          CompanyBankAccountActivated: { populate: true },
          CompanyAccountLogin: { populate: true },
          CompanyBankAccountDownload: { populate: true },
          SocialSecurityAffiliation: { populate: true },
          SocialSecurityAffiliationDownload: { populate: true },
          ProductionEntry: { populate: true },
          ProductEntityCertificate: { populate: true },
        },
      },
    },
  });
};
