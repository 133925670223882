/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-grid-system';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';
import Input from '../Input';
import Select from '../Select';
import Textarea from '../Textarea';

// styles
import './index.scss';
import Checkbox from '../Checkbox';
import InputFile from '../InputFile';
import Spinner from '../Spinner';

function FormGenerator({
  schema,
  buttonValue,
  linkValue,
  link,
  selectData,
  loading,
  onSubmit,
}) {
  /* ******************************** HOOKS ******************************* */
  const { language } = useI18next();
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [clearInput, setClearInput] = useState(false);

  useEffect(() => {
    if (clearInput) {
      setWasSubmitted(false);
      setClearInput(false);
    }
  }, [clearInput, wasSubmitted]);

  /* ******************************** CONSTANTS ******************************* */
  let defaultLabel = 'Envoyer';
  if (language === 'en') {
    defaultLabel = 'Send';
  } else if (language === 'ar') {
    defaultLabel = 'إرسال';
  }

  const INPUTS_TYPES = ['text', 'number', 'email', 'password', 'tel', 'url'];
  const SELECT_TYPE = 'select';
  const TEXTAREA_TYPE = 'textarea';
  const CHECKBOX_TYPE = 'checkbox';
  const FILE_TYPE = 'file';

  const { fields } = schema[language === undefined ? 'fr' : language];
  if (fields === undefined) {
    return null;
  }

  const fieldValuesValidation = {};

  /* ******************************** CALLBACKS ******************************* */

  /* ******************************** RENDER HELPER ******************************* */

  function getFieldError(pattern, type, isRequired, value) {
    if (type === 'file' && value?.name && value?.size) {
      return false;
    }
    if (type !== undefined && type === FILE_TYPE && isRequired) {
      if (value?.name === undefined && value?.size === undefined) {
        return true;
      }
    }
    if (type !== undefined) {
      if (type === CHECKBOX_TYPE && isRequired && value !== 'true') {
        return true;
      }
      if (type === SELECT_TYPE && !String(value.length) > 0) {
        return true;
      }
    }

    if (value === undefined || !value.length > 0) {
      return true;
    }
    if (pattern !== undefined) {
      if (value.match(pattern) === null) {
        return true;
      }
    }
    return false;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let formIsValid = true;

    setWasSubmitted(true);
    const formData = new FormData(e.currentTarget);
    const fieldValues = Object.fromEntries(formData.entries());

    Object.keys(fieldValues).forEach((name) => {
      if (!formIsValid) {
        return;
      }
      const field = fieldValuesValidation[name];
      const value = fieldValues[name];
      const hasError = getFieldError(
        field?.pattern,
        field?.type,
        field?.isRequired,
        value,
      );
      if (hasError) {
        formIsValid = false;
      }
    });

    if (!formIsValid) {
      e.preventDefault();
      return false;
    }

    await onSubmit(fieldValues)
      .then(() => {
        setClearInput(true);
      })
      .catch(() => console.log('error occured'));

    return true;
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="form-generator">
      <form noValidate onSubmit={handleSubmit}>
        <Row className="form-generator-rows" gutterWidth={60}>
          {fields?.map(
            ({
              id,
              type,
              colSpan,
              label,
              name,
              isRequired = false,
              pattern,
              ...props
            }) => {
              fieldValuesValidation[name] = {
                pattern,
                type,
                isRequired,
              };
              return (
                <Col key={id} sm={colSpan.sm} lg={colSpan.lg}>
                  {INPUTS_TYPES.includes(type) && (
                    <Input
                      type={type}
                      name={name}
                      label={label}
                      pattern={pattern}
                      isRequired={isRequired}
                      wasSubmitted={wasSubmitted}
                      clearInput={clearInput}
                      {...props}
                    />
                  )}
                  {SELECT_TYPE === type && (
                    <Select
                      name={name}
                      label={label}
                      height={54}
                      wasSubmitted={wasSubmitted}
                      {...props}
                      // eslint-disable-next-line react/prop-types
                      options={selectData[name] ?? props?.options ?? []}
                      clearInput={clearInput}
                    />
                  )}
                  {TEXTAREA_TYPE === type && (
                    <Textarea
                      type={type}
                      id={name}
                      name={name}
                      label={label}
                      pattern={pattern}
                      isRequired={isRequired}
                      wasSubmitted={wasSubmitted}
                      {...props}
                      clearInput={clearInput}
                    />
                  )}
                  {CHECKBOX_TYPE === type && (
                    <Checkbox
                      type={type}
                      name={name}
                      label={label}
                      isRequired={isRequired}
                      wasSubmitted={wasSubmitted}
                      {...props}
                      clearInput={clearInput}
                    />
                  )}
                  {FILE_TYPE === type && (
                    <InputFile
                      type={type}
                      name={name}
                      label={label}
                      isRequired={isRequired}
                      wasSubmitted={wasSubmitted}
                      {...props}
                      clearInput={clearInput}
                    />
                  )}
                </Col>
              );
            },
          )}
        </Row>
        <Col>
          <button
            type="submit"
            className={`form-generator-button ${loading ? 'loading' : ''}`}
          >
            <span>{buttonValue || defaultLabel}</span>

            {loading && <Spinner />}
          </button>
        </Col>
        <Col>
          <Link to={link}>{linkValue}</Link>
        </Col>
      </form>
    </Container>
  );
}

FormGenerator.propTypes = {
  schema: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape)),
  buttonValue: PropTypes.string,
  linkValue: PropTypes.string,
  link: PropTypes.string,
  selectData: PropTypes.shape,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

FormGenerator.defaultProps = {
  schema: undefined,
  buttonValue: undefined,
  linkValue: undefined,
  link: undefined,
  selectData: {},
  loading: false,
  onSubmit: () => {},
};

export default FormGenerator;
