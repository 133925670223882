/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components

// Style
import './index.scss';

// Assets
import PlusIcon from '../../../images/plus-icon.svg';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function InputFile({
  name,
  type,
  label,
  placeholder,
  fieldErrorMessage,
  wasSubmitted,
  isRequired,
  clearInput,
}) {
  /* ********************************** HOOKS ********************************* */
  const [file, setFile] = useState();

  /* ***************************** CALLBACKS ***************************** */
  function getFieldError() {
    if (isRequired) {
      if (file === undefined) {
        return fieldErrorMessage?.empty;
      }
    }

    return null;
  }

  const errorMessage = getFieldError();
  const displayErrorMessage = wasSubmitted && errorMessage;

  function handleChange(e) {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  }

  useEffect(() => {
    if (clearInput) {
      setFile();
    }
  }, [clearInput]);

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="custom-input-file">
      <label htmlFor={name}>
        <span className="label-value">{label}</span>
        <div className="styled-input-file">
          <span className="icon-container">
            <img src={PlusIcon} alt="Add cv" />
          </span>
          <span
            className={`input-text ${
              file?.name !== undefined ? 'has-file' : ''
            }`}
          >
            {file?.name ?? placeholder}
          </span>
          <input
            type={type}
            id={name}
            name={name}
            onChange={handleChange}
            accept=".pdf"
          />
        </div>
      </label>
      {displayErrorMessage ? (
        <span role="alert" id={`${name}-error`} className="error-message">
          {errorMessage}
        </span>
      ) : null}
    </div>
  );
}

InputFile.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  wasSubmitted: PropTypes.bool,
  isRequired: PropTypes.bool,
  clearInput: PropTypes.bool,
  fieldErrorMessage: PropTypes.objectOf(PropTypes.string),
};

InputFile.defaultProps = {
  name: undefined,
  label: undefined,
  type: undefined,
  placeholder: undefined,
  wasSubmitted: false,
  isRequired: false,
  clearInput: false,
  fieldErrorMessage: undefined,
};

export default InputFile;
