/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
import FormGenerator from '../../../shared/UIKit/FormGenerator';
import schema from '../../../shared/FormSchemas/contactFormSchema.json';
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import { API_CONTACT_FORM } from '../../../shared/ApIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function FormContact() {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();

  const { t } = useTranslation();
  const { description2, description3, description4 } = t('formcontact', {
    returnObjects: true,
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await axios.post(API_CONTACT_FORM, {
        data: {
          ...data,
        },
      });
      setResponse('SUCCESS');
    } catch (error) {
      setResponse('ERROR');
    }
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setResponse();
    }, 3000);
  }, [response]);

  return (
    <Container className="form-contact" fluid>
      <p className="form-contact-header">
        <p className="form-contact-title" align="center">
          {description2}
        </p>
        <p className="form-contact-title" align="center">
          {description3}
        </p>
        <p className="form-contact-title" align="center">
          {description4}
        </p>
      </p>
      <FormGenerator onSubmit={onSubmit} schema={schema} loading={loading} />
      {response && (
        <div
          className={`${response === 'ERROR' ? 'error-msg' : ''} ${
            response === 'SUCCESS' ? 'success-msg' : ''
          } align-c`}
        >
          {response === 'SUCCESS'
            ? 'Votre demande a été enregistrée avec sucess'
            : ''}
          {response === 'ERROR' ? "un message d'erreur s'est produit" : ''}
        </div>
      )}
    </Container>
  );
}

FormContact.propTypes = {};

FormContact.defaultProps = {};

export default FormContact;
