/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components

// Redux actions and selectors

// Helpers & utils

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Checkbox({
  name,
  type,
  label,
  isRequired,
  fieldErrorMessage,
  wasSubmitted,
  clearInput,
}) {
  /* ******************************** HOOKS ******************************* */
  const [checked, setChecked] = useState(false);
  const [touched, setTouched] = useState(false);
  /* ******************************** CALLBACKS ******************************* */
  function getFieldError() {
    if (isRequired) {
      if (!checked) {
        return fieldErrorMessage?.checked;
      }
    }
    return null;
  }

  const errorMessage = getFieldError();
  const displayErrorMessage = (wasSubmitted || touched) && errorMessage;

  function handleChange() {
    setChecked(!checked);
  }

  function handleBlur() {
    setTouched(true);
  }

  useEffect(() => {
    if (clearInput) {
      setChecked(false);
      setTouched(false);
    }
  }, [clearInput]);

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="custom-checkbox">
      <div className="flex-center">
        <input
          type={type}
          id={name}
          name={name}
          required={isRequired}
          value={checked ?? false}
          checked={checked ?? false}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <input
          type="hidden"
          name={name}
          required={isRequired}
          value={checked}
        />
        <label htmlFor={name}>{label}</label>
      </div>
      {displayErrorMessage ? (
        <span role="alert" id={`${name}-error`} className="error-message">
          {errorMessage}
        </span>
      ) : null}
    </div>
  );
}

Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  wasSubmitted: PropTypes.bool,
  clearInput: PropTypes.bool,
  fieldErrorMessage: PropTypes.objectOf(PropTypes.string),
};

Checkbox.defaultProps = {
  type: undefined,
  name: undefined,
  label: undefined,
  isRequired: false,
  wasSubmitted: false,
  clearInput: false,
  fieldErrorMessage: undefined,
};

export default Checkbox;
