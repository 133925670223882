import { sarlFolderQueries } from './queries';

/* eslint-disable */
export const baseUploads =
  'https://preview.admin.exactt-global-partners.emkt.dev';
export const baseUrl =
  'https://preview.admin.exactt-global-partners.emkt.dev/api';
// export const baseUrl = 'http://localhost:1337/api';
// export const baseUploads = 'http://localhost:1337';

export const API_NEWS = (locale) => {
  return `${baseUrl}/news?sort=date%3Adesc&populate=*&locale=${locale}`;
};
export const API_UPLOADS = (file) => {
  return `${baseUploads}${file}`;
};
export const API_NEWS_ID = (id, locale) => {
  return `${baseUrl}/news/${id}/?populate=*&locale=${locale}`;
};
export const API_NEWS_ID_FILTRED = (id, locale) => {
  return `${baseUrl}/news?sort=date%3Adesc&populate=*&filters[id][$ne]=${id}&locale=${locale}`;
};
export const API_TESTIMONIES = (locale) => {
  return `${baseUrl}/testimonies?populate=*&locale=${locale}`;
};

export const API_EXPERTS = `${baseUrl}/experts?populate=*`;
export const API_CONSULTANTS = `${baseUrl}/consultants?populate=*`;
export const API_EXPERT_REQUEST = `${baseUrl}/expert-requests`;
export const API_PARTNER_REQUEST = `${baseUrl}/partner-requests`;

export const API_COUNTRIES = `${baseUrl}/countries?populate=flag&pagination[pageSize]=1000`;
export const API_SOURCE_COUNTRIES = `${baseUrl}/source-countries?populate=country.flag&pagination[pageSize]=1000`;
export const API_TARGET_COUNTRIES = `${baseUrl}/target-countries?populate=country.flag&pagination[pageSize]=1000`;
export const API_DOMAINS = (category, locale) => {
  const categoryFilter =
    category?.length > 0 ? `&filters[category][$eqi]=${category}` : '';
  return `${baseUrl}/domains?populate=*${categoryFilter}&locale=${locale}`;
};

export const API_FUNCTIONS = `${baseUrl}/functions?populate=*`;

export const API_UPLOAD_FILE = `${baseUrl}/upload`;

export const API_DAF_FORM = `${baseUrl}/daf-forms`;
export const API_PARTNER_FORM = `${baseUrl}/partner-forms`;
export const API_CONSULTANT_FORM = `${baseUrl}/consultant-forms`;
export const API_SUPPORT_OFFER_FORM = `${baseUrl}/support-offer-forms`;
export const API_CONTACT_FORM = `${baseUrl}/contact-forms`;

export const API_SHEET = (source, target) => {
  if (
    source !== null &&
    source !== undefined &&
    target !== null &&
    target !== undefined
  ) {
    return `${baseUrl}/dynamic-sheets?filters[paysSource][id][$eq][0]=${source}&filters[paysCible][id][$eq][1]=${target}&populate=paysSource.country.flag,paysCible.country.flag,accords_fiscaux_investissement,accords_commerciaux,economie,institutionnel`;
  }

  if (
    (source === undefined || source === null) &&
    target !== undefined &&
    target !== null
  ) {
    return `${baseUrl}/dynamic-sheets?filters[paysSource][id][$null][0]=true&filters[paysCible][id][$eq][1]=${target}&populate=paysSource.country.flag,paysCible.country.flag,accords_fiscaux_investissement,accords_commerciaux,economie,institutionnel`;
  }
};

export const API_CATEGORIES = (locale = 'fr') =>
  `${baseUrl}/categories?locale=${locale}&populate=icon&pagination[pageSize]=1000`;

export const API_SECTORS = (name, locale = 'fr', category) =>
  `${baseUrl}/activity-sectors?filters[name_${locale}][$containsi][0]=${name}&filters[category][id][$eq][1]=${category}&pagination[pageSize]=1000`;
export const API_TYPES = (locale = 'fr') =>
  `${baseUrl}/company-types?locale=${locale}&populate[0]=icon&populate[2]=video&pagination[pageSize]=1000`;

export const API_SARL = (id, locale = 'fr') =>
  `${baseUrl}/sarls${
    id ? '/' + id : ''
  }?locale=${locale}&populate[0]=partners.target_country.country.flag&populate[1]=owner&populate[2]=activity_sector&populate[3]=category&populate[4]=country&populate[5]=type`;

export const API_SARL_BY_USER = (userId, locale = 'fr') =>
  `${baseUrl}/sarls?filters[users_permissions_user][id][$eq][0]=${userId}&locale=${locale}&populate[0]=partners.target_country.country.flag&populate[1]=owner&populate[2]=activity_sector&populate[3]=category&populate[4]=country&populate[5]=type`;

export const API_REGISTER = `${baseUrl}/auth/local/register`;

export const API_LOGIN = `${baseUrl}/auth/local/`;

export const API_USER = (id) => `${baseUrl}/users/${id}`;

export const API_SARL_FOLDER = (id) =>
  `${baseUrl}/sarl-folders${id ? '/' + id : ''}?${sarlFolderQueries()}`;

export const API_SARL_FOLDERS = (sarlId) =>
  `${baseUrl}/sarl-folders?filters[sarl][id]=${sarlId}&${sarlFolderQueries()}`;

export const API_UPLOAD = `${baseUrl}/upload`;

export const API_NATIONALITIES = `${baseUrl}/nationalities?pagination[pageSize]=1000`;
